import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Features from '../components/Features'
import Testimonials from '../components/Testimonials'
import Pricing from '../components/Pricing'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import MailChimpForm from '../components/MailChimpForm'


export const AudioswitchPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials,
  fullImage,
  pricing,
}) => (
  <div className="content">
 <div
      className="full-width-image margin-top-0 videoBg"
    >

      <video autoplay='true' muted="true" loop="true" playsinline="true" className="video1" style={{top: '-340px'}}>
    <source src="/img/Headphones-home.webm" type="video/webm"/>
    <source src="/img/Headphones-home.mp4" type="video/mp4"/>
</video>
      <div className="videoMessage">
      <h2
        className="has-text-weight-bold is-size-1"
          style={{
            boxShadow:
              'rgb(245, 126, 77) 0.5rem 0px 0px, rgb(245, 126, 77) -0.5rem 0px 0px',
            backgroundColor: 'rgb(245, 126, 77)',
            color: 'white',
            lineHeight: '1',
            padding: '0.25em',
          }}
        
      >
        {title}
<div className="kickstarterLink" style={{textAlign: "center", marginTop: "5px", marginBottom: "5px"}}>
                      <a href="https://www.kickstarter.com/projects/neederland/headphone-stand"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="button is-link">
                           Kickstarter page
                      </a>
                    </div>
      </h2>
    </div>
    </div>

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-7 is-offset-1">
            
  <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>

<h2 className="title is-size-3 has-text-weight-bold is-bold-light">Coming soon, be the first to know!</h2>
    <div id="mailchimpProducts">
    <MailChimpForm/>
</div>
            

              <div className="tile">
                <h4 className="subtitle">{description}</h4></div>

            </div>
          </div>
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <Features gridItems={intro.blurbs} />
    
              <div className="columns">
    
                <div className="column is-7">
                  <h3 className="has-text-weight-semibold is-size-3">
                    {main.heading}
                  </h3>
                  <p>{main.description}</p>

                </div>
              </div>
    
              <div className="tile is-ancestor">
                <div className="tile is-vertical">
                  <div className="tile">
                    <div className="tile is-parent is-vertical">
                      <article className="tile is-child">
                                      <div className="video-container">
      <video autoplay='true' muted="true" loop="true" playsinline="true" className="video">
    <source src="/img/infographic.webm" type="video/webm"/>
    <source src="/img/infographic.mp4" type="video/mp4"/>
</video>
              </div>
                      </article>
                    </div>
                    <div className="tile is-parent">
                      <article className="tile is-child">
                                             <div className="video-container">
      <video autoplay='true' muted="true" loop="true" playsinline="true" className="video">
    <source src="/img/infographic2.webm" type="video/webm"/>
    <source src="/img/infographic2.mp4" type="video/mp4"/>
</video>
              </div>                
                      </article>
                    </div>
                  </div>
                </div>
              </div>
              <div className="video-container">
              <iframe
                  title="youtube video"
                  className="video"
                  src="https://www.youtube-nocookie.com/embed/2HNjlMSk42c"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <Testimonials testimonials={testimonials} />
              <div
                className="peanuts-container"
                style={{
                  backgroundImage: `url(${
                    fullImage.childImageSharp
                      ? fullImage.childImageSharp.fluid.src
                      : fullImage
                  })`,
                }}
              />
              <h2 className="has-text-weight-semibold is-size-2">
                {pricing.heading}
              </h2>
              <p className="is-size-5">{pricing.description}</p>
              <Pricing data={pricing.plans} />
            </div>
          </div>
        </div>
      </div>
    </section>
</div>
)

AudioswitchPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,

  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
}

const AudioswitchPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <AudioswitchPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  )
}

AudioswitchPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default AudioswitchPage

export const AudioswitchPageQuery = graphql`
  query AudioswitchPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
            publicURL 
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
        main {
          heading
          description

        }
        testimonials {
          author
          quote
          comment
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            items
            plan
            price
          }
        }
      }
    }
  }
`
